<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <v-tabs horizontal>
            <!--  -->
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Hasil
            </v-tab>
            <v-tab>
              <v-icon center>
                mdi-account
              </v-icon>
              Responden
            </v-tab>
            <v-tab-item>
              <base-material-card
                title="Hasil"
                icon="mdi-clipboard-check"
              >
                <!--  -->
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-text-field
                          v-model="polling.data.question"
                          label="Pertanyaan"
                          placeholder="Masukan Pertanyaan"
                          disabled
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div
                    v-for="(data, i) in polling.data.options"
                    :key="i"
                  >
                    <!--  -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            v-model="data.option"
                            :label="'Pilihan Ke - ' + (i + 1)"
                            disabled
                          />
                          <v-progress-linear
                            :value="(data.counter / polling.data.votes) * 100"
                            color="primary"
                            height="25"
                            disabled
                          >
                            <strong>{{
                              Math.ceil(
                                (data.counter / polling.data.votes) * 100
                              )
                            }}%</strong>
                          </v-progress-linear>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card>
              </base-material-card>
            </v-tab-item>
            <v-tab-item>
              <base-material-card
                title="Responden"
                icon="mdi-clipboard-check"
              >
                <!--  -->
                <v-simple-table>
                  <thead>
                    <th class="text-left primary--text">
                      No
                    </th>
                    <th class="text-left primary--text">
                      Nama
                    </th>
                    <th class="text-left primary--text">
                      Vote
                    </th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, i) in persen.data"
                      :key="i"
                    >
                      <td class="mr-3">
                        {{ i + persen.meta.from }}
                      </td>
                      <td class="mr-3">
                        {{ data.user.name }}
                      </td>
                      <td class="mr-3">
                        {{ data.option.option }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="persen.meta.last_page"
                    total-visible="5"
                    circle
                    @input="getResults"
                  />
                </div>
              </base-material-card>
            </v-tab-item>
          </v-tabs>
          <!--  -->
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Name',
    data () {
      return {
        polling: {
          meta: {},
          data: {
            question: '',
            settings: {
              anonymous_answer: 0,
              multiple_answer: 0,
              quiz_mode: 0,
            },
            options: [{}],
          },
        },
        openDialog: {
          open: false,
          nama: '',
          vote: '',
        },
        persen: {
          meta: {},
          data: {},
        },
        knowledge: 50,
        power: 20,
        page: parseInt(this.$route.query.page) || 1,
      }
    },
    computed: {
      response () {
        return this.polling.data.reduce((items, data) => {
          return items + (data.options.counter / data.votes) * 100
        }, 0)
      },
    },
    watch: {
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
    },
    mounted () {
      this.getPolling()
      this.getResponse()
    },
    methods: {
      setResponse (data) {
        this.persen = data
      },
      getResponse () {
        axios
          .get('/v1/polling/' + this.$route.params.id + '/response')
          .then(response => {
            this.setResponse(response.data)
          })
      },
      getResults (page = this.page) {
        axios
          .get('/v1/polling/' + this.$route.params.id + '/response')
          .then(response => {
            if (response.data.meta.status) {
              this.setResponse(response.data)
            }
          })
      },
      setPolling (data) {
        this.polling = data
      },
      getPolling () {
        axios
          .get('/v1/polling/' + this.$route.params.id + '?entities=user,options')
          .then(response => {
            this.setPolling(response.data)
          })
      },
    },
  }
</script>

<style></style>
